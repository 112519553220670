//color
$primary-color: #009EE0;

html, body, #accout-manager-app {
  height: 100%;
  background-color: #EAEDF4;
}
.tsme-content-container {
  max-width: 1100px;
}
  
.text-secondary {
  color: $primary-color !important;
}

.sz-aside__item.active, .sz-aside__item.active:hover {
  color: $primary-color;
}
