$primary-color: #009EE0;
$primary-color-lighter: #239de1;
$secondary-color: #030e40;
$third-color: #AFB8CE;
$secondary: #239de1;
$third: #829ED2;
$third-lighter: #809acb;
$third-lighter-shade:rgba(131, 166, 232, 0.57);

$fourth :#becae675;
$fourth-lighter :#cfdbf9b3;
