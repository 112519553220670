.accmgt_table--filters {

  align-items: center;
  padding-bottom: 25px;

  .sz-label-input {
    line-height: 1.5;
    font-weight: 900;
    text-transform: uppercase;
  }

  .sz-form-group__sz-input-text{
    border: 2px solid #829ED2;
    background: #ffffff !important;
  }

  .sz-container-select{
    padding: 0.6rem 0 !important;
    border-color: #829ED2 !important;

    .sz-container-select__chevron{
      top: 15px;
    }
  }

  &__validate {
    display: flex;
    justify-content: space-around;
    top: 15px;

    button.sz-btn {
      margin-bottom: 1rem;
      padding: 1.2rem 0 !important;
      display: inline-flex;
      justify-content: center;
      width: 45%;
    }

    button.sz-btn:focus,
    button.sz-btn:active {
      background: linear-gradient(to right, #829ED2 0%, #072B4E 100%) !important;
      box-shadow: none !important;
    }
  }
}
