.gaia-header {
    border-bottom: 1px rgba(0, 0, 0, 0.27) solid;
    &.unlogged {
      padding-right: 20rem;
    }
    @at-root #{&}__brand {
      line-height: 60px;
      padding: 8px 0;
    }
    @at-root #{&}__menu-icon {
      display: flex;
      font-size: 2.5rem;
      width: 51px;
      justify-content: center;
    }
    @at-root #{&}__app-icon {
      font-size: 1.7rem;
    }
    @at-root #{&}__notif-icon {
      font-size: 2rem;
    }
    @at-root #{&}__signin {
      font-size: 1.8rem;
    }
    @at-root #{&}__signin-text {
      font-size: 1rem;
    }
    @at-root #{&}__brand-title {
      font-size: 2rem;
      padding-top: 10px;
    }
  }
  .gaia-sub-header {
    height: 70px;
  }
