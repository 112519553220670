.accmgt_table {
  padding: 20px 15px;

  .clickable:hover {
    cursor: pointer;
  }

  table.sz-table {
    margin: 0;

    thead {
      tr.bg-light-blue {
        th {
          &:first-child {
            border-left-width: 0;
            &::after {
              background: #019de1;
            }
          }
          &:last-child {
            border-right-width: 0;
          }

        }

      }
    }
      td {
        border-color: white;
        border-style: solid;
        border-width: 0 1px 1px;
        &:first-child {
          border-left-width: 0 ;
          &::after{
            background: #019de1;
          }
        }
        &:last-child {
          border-right-width: 0;
        }

        .icon-check,
        .icon-toggle-switch {
          color: #1c7430;
        }

        .icon-close {
          color: red;
        }


      }
  }

}

.h-35{
  height: 35% !important;
}
