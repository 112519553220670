.gaia-container {
  height: calc(100% - 60px);
  overflow-y: auto;

  &.no-menu {
    width: 100% !important;
  }
  &.no-connected {
  background-color: #000042;
  background-image: url('../../assets/img/bgHome2.png');
  }
  &.connected {
    height: calc(100% - 50px);
    width: calc(100% - 50px);
    transition: 0.5s ease-in;
  }
  &.isOpen {
    width: calc(100% - 200px);
    transition: 0.3s ease;
  }

}
.gaia-aside {
  height: calc(100% - 50px);

}


