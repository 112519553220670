@import '~react-theme-components/assets/scss/1-settings/1-settings';
@import '~react-theme-components/assets/scss/2-tools/2-tools';

.gaia-form {
  max-width: 730px;
  @at-root #{&}__mandatory {
    bottom: $default-spacing * 1.7;
  }
}
.gaia-inscription-form {
  @at-root #{&}__button-group {
    label {
      width: 48%;
    }
  }
  @at-root #{&}__mandatory {
    bottom: $default-spacing * 1.7;
  }
}
@media screen and (min-width: 768px) {
  .gaia-inscription-form {
    @at-root #{&}__form-group {
      width: 50%;
    }
  }
  .gaia-reference-form {
    @at-root #{&}__form-group {
      width: 50%;
    }
  }
}
.block {
  display: block !important;
}
.container-recaptcha {
  bottom: -10px;
  overflow: hidden;
  width: 256px;
  height: 90px;
  z-index: 2;
}
.grecaptcha-badge {
  position: absolute !important;
}


.sz-underline::after{

  background-color: #829ED2;
}

.sz-button-group__btn-choose.active, .sz-button-group__btn-choose:hover, .sz-button-group__btn-choose.focus {
  border-color: #829ED2 !important;
}

.sz-button-group__btn-choose.active i, .sz-button-group__btn-choose:hover i, .sz-button-group__btn-choose.focus i {
  background: #829ED2;
}

.sz-form-group__sz-input-text:focus {
  border: 2px solid #829ED2;
}

.sz-btn {
  color: aliceblue !important;
  background-image: linear-gradient(to right, #829ED2 0%, #072B4E 100%);
}

.btn-secondary:hover {
  color: #212529;
  background: #000042 linear-gradient(180deg, #829ED2, #070753) repeat-x;
  border-color: #000042;
}

.sz-btn[disabled] {
  background: #c1c4cc;
}


.sz-form-group__sz-input-text--checked {
  border: 2px solid #829ED2;
}

.bg-secondary {
  background-color: #829ED2 !important;
}
.light {
  color: white;
}
